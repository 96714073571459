import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Banner from '../components/banner'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
    <Layout>
        <SEO title='404: Not found' />
        <Banner title='Error 404' subTitle='The page you are looking does not exist.' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
        <Container>
            <Row>
                <Col xs='12' sm='12' md='12' lg='12'>
                    <div className='spacer50'></div>
                    <h1>NOT FOUND</h1>
                    <p>Oops! Looks like we can't find what you're looking for at the moment. Contact us for more information or click the back button to keep browsing. We apologize for any inconvenience.</p>
                    <div className='spacer50'></div>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default NotFoundPage
